/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import { graphql } from 'gatsby'

import HomeTemplate from 'src/templates/home'

const HomePage = data => {
  return <HomeTemplate {...data} />
}

export default HomePage

export const pageQuery = graphql`
  {
    page: wordpressPage(wordpress_id: { eq: 437 }) {
      ...homePageFragment
    }

    services: allWordpressPage(filter: {wordpress_id: {in: [26, 28, 30]}}) {
      edges {
        node {
          acf {
            excerpt {
              title
              icon {
                localFile {
                  publicURL
                }
              }
            }
          }
          path
          wpId: wordpress_id
        }
      }
    }
  }
`

